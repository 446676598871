:root {
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --accent-color: #3498db;
  --text-color: #ecf0f1;
  --background-color: #1a1a1a;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.app {
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h1, h2 {
  color: var(--accent-color);
  margin-top: 0;
}

.file-inputs {
  display: flex;
  gap: 10px;
}

.file-inputs input[type="file"] {
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.editor-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main-panel {
  display: flex;
  gap: 20px;
}

.left-panel, .right-panel, .bottom-panel {
  background-color: var(--primary-color);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.left-panel, .right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.video-container video,
.edited-preview-container video {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.timeline-container,
.edited-timeline-container {
  margin-top: 20px;
}

.transcript-container,
.chatbot-container,
.editable-transcript-container {
  background-color: var(--secondary-color);
  padding: 15px;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 300px;
}

.chatbot {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--primary-color);
  border-radius: 5px;
}

.message {
  margin-bottom: 10px;
  padding: 5px 10px;
  border-radius: 5px;
}

.message.user {
  background-color: var(--accent-color);
  align-self: flex-end;
}

.message.bot {
  background-color: var(--secondary-color);
  align-self: flex-start;
}

form {
  display: flex;
  gap: 10px;
}

select, input[type="text"], button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

button {
  cursor: pointer;
  background-color: var(--accent-color);
}

button:hover {
  opacity: 0.9;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.spinner {
  border: 4px solid var(--secondary-color);
  border-top: 4px solid var(--accent-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 1024px) {
  .main-panel {
    flex-direction: column;
  }

  .left-panel, .right-panel {
    width: 100%;
  }
}